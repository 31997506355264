import http from '../../../utils/services/http'

export const habilitarProdutividadeProcesso = (idProcesso) => {
    let url = `/api/produtividade/${idProcesso}`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const listTipoEtapas = () => {
    let url = `/api/tipo-etapas-produtividade`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const listTipoEtapasFull = () => {
    let url = `/api/tipo-etapas-produtividade/list`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
    let url = `/api/produtividade/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findByProcesso = (id) => {
    let url = `/api/produtividade/findByProcesso/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const stats = () => {
    let url = `/api/produtividade/stats`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listTiposCanaisCiencia = (limit, page, filtros) => {
    let url = `/api/produtividade-canais-ciencia?page=${page}&limit=${limit}${filtros}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateEtapa = (id, data) => {
    let url = `/api/produtividade/etapas/${id}`
    return http.patch(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePessoaEtapa = (etapa, person) => {
    let url = `/api/produtividade/etapas/${etapa.id}/pessoaRelacionada`
    return http.post(url, person)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deletePessoaEtapa = (etapa, relacao) => {
    let url = `/api/produtividade/etapas/${etapa.id}/pessoaRelacionada/${relacao}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const registraPagamentoEtapa = (etapa, pagamento) => {
    let url = `/api/produtividade/etapas/${etapa.id}/pagar`
    return http.post(url, pagamento)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const getPagamentosEtapaPessoa = (id) => {
    let url = `/api/produtividade/etapas/${id}/pagamentos`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const uploadArquivoEtapa = (id, data) => {
    let url = `/api/produtividade/etapas/${id}/arquivos`
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteArquivoEtapa = (etapa, arquivo) => {
    let url = `/api/produtividade/etapas/${etapa}/arquivos/${arquivo}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadArquivoEtapa = (etapa, arquivo, inline = false) => {
    let url = `/api/produtividade/etapas/${etapa.id}/arquivos/${arquivo}/download`
    if (inline) {
        url = url + '?inline=true'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const adicionarIntimacao = (data) => {
    let url = `/api/produtividade/etapas/intimacoes`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateIntimacao = (id, data) => {
    let url = `/api/produtividade/etapas/intimacoes/${id}`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findIntimacao = (id) => {
    let url = `/api/produtividade/etapas/intimacoes/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteIntimacao = (id) => {
    let url = `/api/produtividade/etapas/intimacoes/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const exportarProdutividade = () => {
    let url = '/api/relatorios/produtividade?export=excel'
    let config = {}
    config.responseType = 'blob'
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
